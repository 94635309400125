import React from 'react'
import BlogHeader from '../../BlogHeader'

const NoosNooluur = () => {
    return (
        <div>
            <BlogHeader title={'noos-nooluur'} />
            <div className="mx-auto max-w-7xl my-8 md:gap-4 bg-white p-4">
                <div>
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        НООС, НООЛУУР СУДЛАЛЫН ЛАБОРАТОРИ
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>

                        <p><strong>Үндсэн үйл ажиллагаа: </strong></p>
                        <p>Мал амьтны гаралтай ширхэгтийн (ноос, ноолуур, хялгас) чанарын задлан шинжилгээ хийх</p>
                        <p className='mt-6'><strong>Судалгааны чиглэл: </strong></p>
                        <p>Шилмэл омог, үүлдрийн ямааны ноолуурын чанарын (40000 гаруй ямааны)  мэдээллийн сантай.</p>
                        <ul className='list-disc pl-8'>
                            <li>Мал амьтны гаралтай ширхэгтийн (ноос, ноолуур, хөөвөр, хялгас) чанарын задлан шинжилгээ хийх; </li>
                            <li>Монгол оронд үржүүлж буй нутгийн болон шилмэл омог, үүлдрийн малын ноос, ноолуурын чанарын үзүүлэлтийг тодорхойлох;</li>
                            <li>Бусад төрлийн мал амьтны гаралтай ширхэгт материалд судалгаа хийх үндсэн чиглэлтэй.</li>
                        </ul>

                    </div>
                    <hr className='my-6' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-auto'>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src="/background-image/riah/Picture4.jpg"
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt="Taking up Water with a Spoon" />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                            Мал амьтны гаралтай ширхэгтийн голчийг 10.000 микрометрийн нарийвчлалтайгаар хэмждэг
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src="/background-image/riah/Picture6.jpg"
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt="Taking up Water with a Spoon" />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                            Мал амьтны гаралтай ноос, ноолуурын дээж
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src="/background-image/riah/Picture7.jpg"
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt="Taking up Water with a Spoon" />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                            Шинээр ирсэн OFDA2000 багажийг ажиллагаанд оруулж байгаа нь
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src="/background-image/riah/Picture8.jpg"
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt="Taking up Water with a Spoon" />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                            Ноос, ноолуурын уртыг тодорхойлж байгаа нь
                        </figcaption>
                    </figure>
                </div>
            </div>
        </div>
    )
}

export default NoosNooluur