import React from 'react'
import ComponentLaborator from './ComponentLaborator';

const Ediinusguvur = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture34.jpg",
            description: "Тэжээлийн ургамлын In vitro суулгац"
        },
        {
            imgpath: "/background-image/riah/Picture35.jpg",
            description: "Бактерийн өсгөврийн суулгац"
        },
        {
            imgpath: "/background-image/riah/Picture36.jpg",
            description: "Царгасны in vitro бичил үржүүлэг"
        },
        {
            imgpath: "/background-image/riah/Picture37.jpg",
            description: "In vitro-оос in vivo шилжүүлэн суулгасан бичил ургамал"
        },
    ];

    const mainservice = 'Тэжээлийн шимт чанар өндөртэй малын тэжээлийн ургамал, ганд тэсвэртэй, соёололтоо түргэн алддаг болон үрээр муу үрждэг ургамлуудыг биотехнологийн аргаар богино хугацаанд ургуулж, эх материал гарган авах, ургамлын абиотик стресс, тэсвэржилтийг үнэлэх'
    const chiglel = [
        'Жилдээ 10-13 зүйл ургамлын стресс тэсвэрлэлтийг тогтоож, 600-800 ш эх ургамал гадна талбайд шилжүүлэх боломжтой. ',
        'Тэжээлийн шимт чанар өндөртэй болон бусад онцлог ургамлуудын эдийн өсгөврийг биотехнологийн аргаар богино хугацаанд гарган авах; ',
        'Тэжээлийн ургамлыг ургаа байдлаар хадгалах, үржүүлэх, нөөцийг нэмэгдүүлэх;',
        'Ургамлын абиотик стресс, тэсвэржилтийг тогтоох;',
        'Ургамлын генийн инженерчлэлийн судалгаа хийх;',
    ];
    return (
        <ComponentLaborator
            title={'ediin-usguvur'}
            photos={photos}
            maintitle={'УРГАМЛЫН ЭДИЙН ӨСГӨВРИЙН ЛАБОРАТОРИ'}
            mainservice={mainservice}
            chiglel={chiglel}
        />
    )
}

export default Ediinusguvur