import React from 'react'
import BlogHeader from '../../BlogHeader'

const TuviinBus = () => {
    return (
        <div>
            <BlogHeader title={'tuv-bus'} />
            <div className="mx-auto max-w-7xl py-8 md:gap-4 bg-white px-8">
                <div className="flex flex-wrap justify-center items-center my-4">
                    <img
                        width={120}
                        src="/background-image/riah/Picture58.jpg"
                        alt="ТӨВ АЙМГИЙН БАТСҮМБЭР СУМ ДАХЬ ЗӨГИЙН АЖ АХУЙН  ЭРДЭМ ШИНЖИЛГЭЭ, ҮЙЛДВЭРЛЭЛИЙН СТАНЦ"
                    />
                    <div className='text-center text-primary-700 uppercase font-semibold p-3 max-w-xl'>
                        ТӨВ АЙМГИЙН БАТСҮМБЭР СУМ ДАХЬ ЗӨГИЙН АЖ АХУЙН  ЭРДЭМ ШИНЖИЛГЭЭ, ҮЙЛДВЭРЛЭЛИЙН СТАНЦ
                    </div>
                </div>
                <hr className='my-6' />
                <div className='text-justify text-gray-700 text-sm font-medium'>
                    <p className='my-3'>
                        <strong>Товч түүх: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>“Зөгийн аж ахуйн эрдэм шинжилгээ-үйлдвэрлэлийн станц” анх 1967 онд байгуулагдсан. </li>
                        <li>МУЗГ-ын 2006 оны 210 дугаар тогтоолоор Хөдөө Аж Ахуйн Их Сургуулийн харьяа Зөгийн аж ахуйн эрдэм шинжилгээ туршилтын станц, </li>
                        <li>2015 оны 27 дугаар тогтоолоор МААЭШХ-гийн харьяанд шилжиж Төв аймгийн Батсүмбэр суман дахь “Зөгийн аж ахуйн эрдэм шинжилгээ-үйлдвэрлэлийн станц” нэртэйгээр Карпат болон Халиун удмын зөгий үржүүлж байна.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Судалгааны ажлын чиглэл: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Зөгийн аж ахуйг шинжлэх ухааны үндэслэлтэй хөтлөн явуулах, цаашид хөгжүүлэх стратеги бодлогыг боловсруулах, зөгийн аж ахуйгаас импортыг орлох бүтээгдэхүүн үйлдвэрлэх</li>
                        <li>Зөгийн аж ахуйг зохион байгуулалт, санхүүгийн хувьд бэхжүүлэх </li>
                        <li>Тэжээл байрны хангамж, бэлчээр ашиглалтыг сайжруулах</li>
                        <li>Бүтээгдэхүүн үйлдвэрлэлийг нэмэгдүүлэх</li>
                        <li>Зөгийн гаралтай брэнд бүтээгдэхүүн бий болгох</li>
                        <li>Судалгаа шинжилгээний төсөл хөтөлбөр боловсруулж, хэрэгжүүлэх  </li>
                        <li>Бэлчээрийн балт, тоост ургамлын тархалт, шимт чанарын судалгаа хийх </li>
                        <li>Зөгийнд тохиолддог халдварт болон халдваргүй өвчнийг судалж, оношилгоо, эмчилгээний арга боловсруулах</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Судалгааны ажлын товч үр дүн: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Зөгийн аж ахуйд мөрдөх технологи 3, стандарт 11, нэгэн сэдэвт бүтээл 5, шинэ бүтээл 1, ном товхимол 60, эрдэм шинжилгээ, судалгааны тайлан, арга зүй 20, судалгааны өгүүлэл, нийтлэл 400 гаруйг боловсруулсан. </li>
                        <li>2011-2013 онд “Халиун удмын зөгийн бүлэнцэр бойжуулж, бүл өсгөх” инновацийн төслийн хүрээнд авгалдай 2 дахин зөөвөрлөн, эх зөгий гарган авч, үржлийн бүлийн тоог 80 бүлээр нэмэгдүүлсэн.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Зөгийн аж ахуйгаас авах бүтээгдэхүүн: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>20000 гаруй зүйлийн зөгий байдгаас 4 зүйл нь бидний хүнсэндээ хэрэглэдэг балыг бий болгодог. </li>
                        <li>Зөгийг төрөлжсөн болон туслах аж ахуйн хэлбэрээр үржүүлж хүнс, эмчилгээний ач холбогдолтой бал, лав, жилий, сүүнцэр, хор, цэцгийн тоос, тоосормог зэрэг бүтээгдэхүүн үйлдвэрлэхийн зэрэгцээ хөдөө аж ахуйн таримал ургамлын тоос хүртээхэд ургац нь 40-300% хүртэл дээшилж, үр жимсний чанар сайжирдаг байна.</li>
                        <li>Баланд ус 18-20, хуурай бодис 80-82% байхаас гадна төрөл бүрийн нян устгагч бодис, витамин, цардуул, уураг агуулагддаг. Зарим төрлийн бал цацраг идэвхт бодисоос хамгаалах үйлчилгээ үзүүлдэг.	</li>
                    </ul>
                </div>
                <div className='flex flex-wrap items-center mx-auto my-6'>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture59.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Зөгийн станцын анхны баг хамт олон
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture60.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Үзлэг хийж байгаа нь
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture61.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            2018, 2019-2020 оны брэнд бүтээгдэхүүн
                        </figcaption>
                    </figure>
                </div>
                <hr className='my-6' />
            </div>
        </div>
    )
}

export default TuviinBus