import React from 'react'
import BlogHeader from '../../BlogHeader'

const Genetic = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture9.jpg",
            description: "Ямаа, сарлаг, үхрийн ДНХ ялган SNP чипд суулгаж  байгаа нь"
        },
        {
            imgpath: "/background-image/riah/Picture10.jpg",
            description: "Популяцийн генетикийн мэдээлэлд боловсруулалт хийх компьютер программ"
        },
    ];
    return (
        <div>
            <BlogHeader title={'genetic'} />
            <div className="mx-auto max-w-7xl my-8 md:gap-4 bg-white p-4">
                <div>
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        МОЛЕКУЛ БИОЛОГИ, ГЕНЕТИК СУДЛАЛЫН ЛАБОРАТОРИ
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>

                        <p><strong>Үндсэн үйл ажиллагаа: </strong></p>
                        <p>
                            Малд удам зүйн үнэлгээ хийх,  үржлийн ажлын төлөвлөлт, зорилгыг тогтоох, түүний үр ашгийн тооцох
                        </p>
                        <p><strong>Судалгааны чиглэл: </strong></p>
                        <ul className='list-disc pl-8'>
                            <li>Малын зүйлүүдийн генетик олон янз байдал, төрлийн нийлүүлгийн түвшин тодорхойлох,</li>
                            <li>Малын бүтээгдэхүүн үйлдвэрлэлд ач холбогдол бүхий кандидат ген, түүний аллелийг илрүүлэх, давтамжийг тодорхойлох,</li>
                            <li>Малын генетик нөөцийг тодорхойлох, үнэлэх, эцэг эх, үр төлийн генетик хамаарлыг тогтоох</li>
                            <li>Малын үржлийн үнэлгээг тооцох</li>
                        </ul>
                    </div>
                    <hr className='my-6' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-auto'>
                    {photos.map((item) => {
                        return <figure className="mb-4 inline-block max-w-sm mx-auto">
                            <img
                                src={item.imgpath}
                                width={"354"}
                                className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                                alt={item.description} />
                            <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                                {item.description}
                            </figcaption>
                        </figure>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Genetic