import React from 'react'
import BlogHeader from '../../BlogHeader';

const ComponentLaborator = ({
    title, photos = [], maintitle,
    mainservice, chiglel, uureg
}) => {
    return (
        <div>
            <BlogHeader title={title} />
            <div className="mx-auto max-w-7xl my-8 md:gap-4 bg-white p-4">
                <div>
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        {maintitle}
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>

                        {mainservice && <>
                            <p><strong>Үндсэн үйл ажиллагаа: </strong></p>
                            <p>
                                {mainservice}
                            </p>
                        </>}

                        {chiglel && <>
                            <p className='mt-6'><strong>Судалгааны чиглэл: </strong></p>
                            <ul className='list-disc pl-8'>
                                {chiglel.map((item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </>}
                        {uureg && <>
                            <p><strong>Салбарын чиг үүрэг: </strong></p>
                            <ul className='list-disc pl-8'>
                                {uureg.map((item) => {
                                    return <li>{item}</li>
                                })}
                            </ul>
                        </>}
                    </div>
                    <hr className='my-6' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-auto'>
                    {photos.map((item) => {
                        return <figure className="mb-4 inline-block max-w-sm mx-auto">
                            <img
                                src={item.imgpath}
                                width={"354"}
                                className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                                alt={item.description} />
                            <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                                {item.description}
                            </figcaption>
                        </figure>
                    })}
                </div>
            </div>
        </div>
    )
}

export default ComponentLaborator