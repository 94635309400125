import React from 'react'
import ComponentLaborator from './ComponentLaborator';

const Hurs = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture42.jpg",
            description: "Хөрсний механик бүрэлдэхүүн              "
        },
        {
            imgpath: "/background-image/riah/Picture43.jpg",
            description: "pH метр                       "
        },
        {
            imgpath: "/background-image/riah/Picture44.jpg",
            description: "Фосфор тодорхойлох багаж"
        },
        {
            imgpath: "/background-image/riah/Picture45.jpg",
            description: "Хөрсний зүсэлт хийсэн байдал"
        },
    ];

    const mainservice = 'Бэлчээрийн болон тариалангийн талбайн хөрсний физик, химийн шинж чанарыг тодорхойлж байна.'
    const chiglel = [
        'Бэлчээрийн хөрсний физик шинж чанар, ургамалд ашиглагдах шимт бодисын хэмжээг тодорхойлох',
        'Тариалангийн талбайн хөрсний физик, агрохимийн чанарын зарим үзүүлэлтийг тодорхойлох',
    ];
    return (
        <ComponentLaborator
            title={'hurs'}
            photos={photos}
            maintitle={'ХӨРС СУДЛАЛЫН ЛАБОРАТОРИ'}
            mainservice={mainservice}
            chiglel={chiglel}
        />
    )
}

export default Hurs