import React from 'react'
import BlogHeader from '../../BlogHeader'

const Genopond = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture21.jpg",
            description: "Лабораторийн хамт олон (2021 он)"
        },
        {
            imgpath: "/background-image/riah/Picture22.jpg",
            description: "Барга үүлдрийн хуцны анги, чанарын үнэлгээ, Өвөрхангай аймаг (2014 он)"
        },
        {
            imgpath: "/background-image/riah/Picture23.jpg",
            description: "Бухны анги, чанарын үнэлгээ, арга зүйн зөвлөгөө (2020 он)"
        },
        {
            imgpath: "/background-image/riah/Picture24.jpg",
            description: "Буган халиун үүлдрийн хэсгийн үхэр сүрэг (2021 он)"
        },
    ];
    return (
        <div>
            <BlogHeader title={'genopond'} />
            <div className="mx-auto max-w-7xl my-8 md:gap-4 bg-white p-4">
                <div>
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        МАЛЫН ҮРЖҮҮЛГИЙН ТЕХНОЛОГИ, ГЕНОФОНДЫН ЛАБОРАТОРИ
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>

                        <p><strong>Үндсэн үйл ажиллагаа: </strong></p>
                        <p>
                            Монгол малын аж ахуй биологи онцлог, ашиг шимийг бататган сайжруулах, хадгалах, хамгаалах, нэмэгдүүлэх зорилгоор үржүүлэг селекцийн сонгомол арга боловсруулж, уур амьсгалын өөрчлөлтөд дасан зохицсон, тэсвэрт чанар өндөртэй малыг сонгох, үржүүлэх шинжлэх ухааны үндэслэлтэй үржүүлгийн хувилбаруудыг боловсруулж,  зохистой хувилбарыг бий болгох.

                        </p>
                        <p className='mt-6'><strong>Судалгааны чиглэл: </strong></p>
                        <p>
                            Монгол орны байгаль цаг уурын өвөрмөц нөхцөлд дасан зохицсон тэсвэр хатуужилтай, өндөр ашиг шим бүхий адуу, үхэр, тэмээ, хонь, ямааны бие даасан үүлдэр, үүлдрийн хэсэг, омог, үржлийн хэсгүүдийг бий болгох, өөр дотор нь сонгон үржүүлж, ашиг шим үүлдэрлэг байдлыг бататгах  үржил селекцийн ажлын урт хугацааны төлөвлөгөө боловсруулах.
                        </p>
                    </div>
                    <hr className='my-6' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-auto'>
                    {photos.map((item) => {
                        return <figure className="mb-4 inline-block max-w-sm mx-auto">
                            <img
                                src={item.imgpath}
                                width={"354"}
                                className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                                alt={item.description} />
                            <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                                {item.description}
                            </figcaption>
                        </figure>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Genopond