import React from 'react'
import BlogHeader from '../../BlogHeader'

const Biotechnolog = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture11.jpg",
            description: "Нарийн нарийвтар ноост Орхон хонь"
        },
        {
            imgpath: "/background-image/riah/Picture12.jpg",
            description: "Орхон үүлдрийн хонинд лапораскопын аргаар Австралийн Меринос хонины хөврөл шилжүүлэн суулгав"
        },
        {
            imgpath: "/background-image/riah/Picture13.jpg",
            description: "Альпин үүлдрийн ямааны хөврөлийг тээгч ямаанд шилжүүлэн суулгав"
        },
        {
            imgpath: "/background-image/riah/Picture14.jpg",
            description: "Лабораторийн одоогийн хамт олон"
        },
    ];
    return (
        <div>
            <BlogHeader title={'biotechnolog'} />
            <div className="mx-auto max-w-7xl my-8 md:gap-4 bg-white p-4">
                <div>
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        МАЛЫН НӨХӨН ҮРЖИХҮЙН БИОТЕХНОЛОГИЙН ЛАБОРАТОРИ
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>

                        <p><strong>Үндсэн үйл ажиллагаа: </strong></p>
                        <p>
                            Малын  төрөл тус бүрт нөхөн үржихүйн физиологийн онцлог, үржлийн материалыг хадгалах, түүний хээл тогтолтыг дээшлүүлэх  дэвшилтэд технологиудыг судлах

                        </p>
                        <p><strong>Судалгааны чиглэл: </strong></p>
                        <ul className='list-disc pl-8'>
                            <li>Өндөр ашиг шимт малын үр, хөврөл үйлдвэрлэх, хадгалах технологийг турших</li>
                            <li>Гүн хөлдөөсөн үр, хөврөлийн хээл тогтоох чадварыг дээшлүүлэх арга аргачлалыг боловсронгуй болгох</li>
                            <li>Гадна орчинд үр хөврөлийг бий болгож,  тээгчид шилжүүлэн суулгах технологийг турших</li>
                            <li>Селекцийн ажлын үр дүнд бий болсон болон гадаадын өндөр ашиг шимтэй донор малыг зохиомол хээлтүүлэг болон үр хөврөл шилжүүлэх аргаар өсгөн олшруулах дэвшилтэд техникийг судлах </li>

                        </ul>
                    </div>
                    <hr className='my-6' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-auto'>
                    {photos.map((item) => {
                        return <figure className="mb-4 inline-block max-w-sm mx-auto">
                            <img
                                src={item.imgpath}
                                width={"354"}
                                className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                                alt={item.description} />
                            <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                                {item.description}
                            </figcaption>
                        </figure>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Biotechnolog