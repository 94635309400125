import React from 'react'
import BlogHeader from '../../BlogHeader'

const BatsumberTuv = () => {
    return (
        <div>
            <BlogHeader title={'batsumber-tuv'} />
            <div className="mx-auto max-w-7xl py-8 md:gap-4 bg-white px-8">
                <div className="flex flex-wrap justify-center items-center my-4">
                    {/* <img
                        width={120}
                        src="/background-image/riah/Picture58.jpg"
                        alt="ТӨВ АЙМГИЙН БАТСҮМБЭР СУМ ДАХЬ ТУРШИЛТ, СУДАЛГААНЫ ТӨВ"
                    /> */}
                    <div className='text-center text-primary-700 uppercase font-semibold p-3 max-w-xl'>
                        ТӨВ АЙМГИЙН БАТСҮМБЭР СУМ ДАХЬ ТУРШИЛТ, СУДАЛГААНЫ ТӨВ
                    </div>
                </div>
                <hr className='my-6' />
                <div className='text-justify text-gray-700 text-sm font-medium'>
                    <p className='my-3'>
                        Туршилт, судалгааны төв  нь нийт 16 га талбайтайгаас
                        Нутгийн тэжээлийн олон наст ургамал эрлийз царгасны
                        “Бургалтай”, дагуур өлөнгийн “Хөдөө арал”, саман ерхөгийн
                        “Сүмбэр-1”, “Чулуут”, эмзэг түрүүт өлөнгийн “Мандал”,
                        соргүй согооврын “Тамир”, сибирь өлөнгийн “Хэрлэн”,
                        шүдлэг хошооны “Сэлэнгэ-1”, Сибирь хялганы “Нарт-1”
                        сортуудын анхдагч үрийн аж ахуйн талбай, гадаадын тэжээлийн
                        ургамлуудын сорт, дээжүүдийг сорьж турших талбай, тэжээлийн
                        олон наст ургамлын генофондын лабораторийн үр шинэчлэх
                        судалгааны талбайнуудтай
                    </p>
                    <p className='my-3'>
                        Үйлдвэрлэлд нэвтрүүлэх зорилгоор нутгийн олон
                        наст ургамал Саман ерхөгийн “Сүмбэр-1” сортын
                        7 га, Эмзэг түрүүт өлөнгийн “Мандал” сортын 1
                        га, сибирь хялганы “Нарт-1” сортын 0.5 га үр
                        үржүүлгийн талбайтай болж судалгааны төвийн
                        нийт газрыг бүрэн ашиглаж байна.
                    </p>
                </div>
                <div className='flex flex-wrap items-center mx-auto my-6'>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture67.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Судалгааны төвийн техник
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture68.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Судлаачдын байр
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture69.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Худаг, усан сан
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture70.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Туршлагын тариалалт
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture71.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Туршлага хүлээн авалт
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture72.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Үр үйлдвэрлэл

                        </figcaption>
                    </figure>
                </div>
                <hr className='my-6' />
            </div>
        </div>
    )
}

export default BatsumberTuv