import React from 'react'
import BlogHeader from '../../BlogHeader'

const DornodBus = () => {
    return (
        <div>
            <BlogHeader title={'dornod-bus'} />
            <div className="mx-auto max-w-7xl py-8 md:gap-4 bg-white px-8">
                <div className="flex flex-wrap justify-center items-center my-4">
                    <img
                        width={120}
                        src="/background-image/riah/Picture52.jpg"
                        alt="ДОРНОД БҮСИЙН СУРГАЛТ, ЭРДЭМ ШИНЖИЛГЭЭ, ҮЙЛДВЭРЛЭЛИЙН ХҮРЭЭЛЭН"
                    />
                    <div className='text-center text-primary-700 uppercase font-semibold p-3 max-w-xl'>
                        ДОРНОД БҮСИЙН СУРГАЛТ, ЭРДЭМ ШИНЖИЛГЭЭ, ҮЙЛДВЭРЛЭЛИЙН ХҮРЭЭЛЭН
                    </div>
                </div>
                <hr className='my-6' />
                <div className='text-justify text-gray-700 text-sm font-medium'>
                    <p className='my-3'>
                        Зүүн бүсийн ХАА-н үйлдвэрлэлийг эрчимжүүлэн хөгжүүлэх чиглэлээр
                        эрдэм шинжилгээ, судалгааны ажил гүйцэтгэж үр дүнг нь үйлдвэрлэлд нэвтрүүлэх,
                        газар тариалан мал аж ахуйн бүтээгдэхүүн үйлдвэрлэж зах зээлд нийлүүлэхэд
                        байгууллагын зорилго оршино.

                    </p>
                    <p className='my-3'>
                        <strong>Байгууллагын түүх: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>1960 онд Халхголын ХАА-н эрдэм шинжилгээний туршлага станц;</li>
                        <li>1966 онд Дорнод талын бүсийн ХААЭШ-ний нэгдсэн станц;</li>
                        <li>1987 онд Дорнод бүсийн ХАА-н эрдэм шинжилгээний хүрээлэн;</li>
                        <li>1993 онд Дорнод бүсийн ХАА-н сургалт эрдэм шинжилгээний хүрээлэн;</li>
                        <li>1997 онд ХААИС-ийн Дорнод бүсийн салбар;</li>
                        <li>2008 онд ХААИС-ийн харьяа Дорнод бүсийн эрдэм шинжилгээ үйлдвэрлэлийн төв;</li>
                        <li>2015 онд МААЭШХ-ийн харьяа Дорнод бүсийн сургалт эрдэм шинжилгээ үйлдвэрлэлийн хүрээлэн гэж нэрлэх болсон.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Үйл ажиллагааны үндсэн чиглэл: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Говийн бүсийн ХАА-н эрдэм шинжилгээний туршлага станц нь анх байгуулагдахдаа говийн нөхцөлд хөдөө аж ахуйн төрөл бүрийн салбарт эрдэм шинжилгээ, судалгааны ажил явуулах, шинжлэх ухаан техник технологийн сүүлийн үеийн ололт амжилт, шинэ санаачилга, тэргүүний туршлагыг судлан үр дүнг үйлдвэрлэлд нэвтрүүлэх замаар мал аж ахуй, газар тариаланг тогтвортой хөгжүүлэх арга боловсруулах онол, практикийн үндсийг тавихад чиглэсэн үйл ажиллагаа явуулж байсан.  </li>
                        <li>Говийн бүсийн бэлчээрийн мал аж ахуйн эрдэм шинжилгээний хүрээлэн нь  говийн бүсийн малын чанар, үүлдэр угсааг сайжруулах, нутгийн шилмэл малын удмын сан, генетик нөөц, бэлчээрийг хамгаалах, тэжээлийн шинэ нөөц бий болгох чиглэлээр суурь болон хавсарга судалгаа явуулах</li>
                        <li>Говийн бүсийн байгаль цаг уур, экологийн нөхцөлд дасан зохицсон, ашиг шим сайтай шинэ үүлдэр, омгийн мал, ургамлын шинэ сорт бий болгох, илрүүлэн баталгаажуулах</li>
                        <li>Говийн бүс нутагт өөрийн орны болон гадаадын өндөр технологийг нутагшуулах, инноваци бүтээгдэхүүн бий болгох чиглэлээр үйл ажиллагаа явуулж байна.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Амжилтын түүх: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Халхголын бүс нутагт монгол орны газар тариаланд тохиромжтой үржил шим сайтай хөрсний 23% буюу 273 мян.га, хадлангийн талбайн 20% буюу 320 мян.га талбай байгааг илрүүлсэн.</li>
                        <li>Монголд анх тариалангийн хөрсийг салхины элэгдлээс хамгаалах, зурваслан тариалах аргыг нэвтрүүлж 10 км ойн зурвас байгуулсан.</li>
                        <li>40 га талбайд жимс-жимсгэний эх цэцэрлэг байгуулсан.</li>
                        <li>63 нэр төрлийн таримлын 1500 гаруй сорт дээжийг тарьж, туршин 100 гаруй сортыг нутагшуулсан.</li>
                        <li>Манай орны тариалангийн аль ч бүсэд дасан зохицож тогтвортой ургац өгч түргэн боловсордог улаан буудайн Халхгол-1 сортыг бүтээсэн.</li>
                        <li>Дорнод талын махны хэвшлийн мухар удмын үхэр бий болгосон.</li>
                        <li>Халхгол САА байгуулах ТЭЗҮ-ийг боловсруулахад гол үүрэг гүйцэтгэснээр уг аж ахуй байгуулагдсан.</li>
                        <li>Монгол улсад анх удаа цагаан будаа тарьж туршин ургах боломжтойг нотолсон.</li>
                        <li>Монгол улсад анх удаа ургамлын тос гаргаж техникийн зориулалтаар барааны саван үйлдвэрлэсэн.</li>
                        <li>Дорнод аймгийн газар тариаланг эрхлэн хөтлөх систем боловсруулж жил бүрийн цаг уурын онцлогт тохирсон агро мөрдлөг гаргаж байна.</li>
                        <li>Тариалангийн талбайн хөрсийг салхины элэгдлээс хамгаалахад сүрлэн хучлага хэрэглэх аргыг анх судлан нэвтрүүлсэн.</li>
                        <li>Дорнод бүсэд тариалах боломжтой таримал ургамлын тарих технологийг боловсруулсан.</li>
                        <li>Шинжлэх ухааны докторын зэргийг 4 ажилтан, боловсролын докторын зэргийг 15 ажилтан хамгаалсан.</li>
                    </ul>
                </div>
                <div className='flex flex-wrap items-center mx-auto my-6'>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture53.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={"Доктор (Ph.D) Ц.Энхтуяа Халх суман дахь үр үржүүлгийн талбайд (2021 он)"} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Доктор (Ph.D) Ц.Энхтуяа Халх суман дахь үр үржүүлгийн талбайд (2021 он)
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture54.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={"ЭШ-ний ажилтан Д.Отгонжаргал зөгийн талбайд үзлэг хийж байгаа нь (Халхгол сум, 2021 он)"} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            ЭШ-ний ажилтан Д.Отгонжаргал зөгийн талбайд үзлэг хийж байгаа нь (Халхгол сум, 2021 он)
                        </figcaption>
                    </figure>
                </div>
                <hr className='my-6' />
            </div>
        </div>
    )
}

export default DornodBus