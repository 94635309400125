
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Bars3Icon, ChevronRightIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Dialog, Disclosure } from '@headlessui/react'
import { classNames } from '../service/CoreFunctions'

const navigation = [
  {
    name: 'Бидний тухай', submenu: [
      { name: 'Захирлын мэндчилгээ', href: 'zahiral-mendchilgee' },
      { name: 'Бидний тухай', href: 'bidnii-tuhai' },
    ]
  },
  {
    name: 'Лабораториуд',
    submenu: [
      {
        name: 'Мал аж ахуйн салбар', submenu: [
          { name: 'Ноос, ноолуурын лаборатори', href: 'noos-nooluur' },
          { name: 'Генетикийн лаборатори', href: 'genetic' },
          { name: 'Биотехнологийн лаборатори', href: 'biotechnolog' },
          { name: 'Махны лаборатори', href: 'mah' },
          { name: 'Сүүний лаборатори', href: 'suu' },
          { name: 'Генофондын лаборатори', href: 'genopond' },
        ]
      },
      {
        name: 'Бэлчээр тэжээлийн салбар',
        submenu: [
          { name: 'Бэлчээрийн мониторинг зураглалын лаборатори', href: 'belcheer' },
          { name: 'Тэжээл үнэлгээний лаборатори', href: 'tejeel' },
          { name: 'Тэжээлийн ургамлын генофонд таримал тэжээл судлалын лаборатори', href: 'urgamal-geopond' },
          { name: 'Ургамлын эдийн өсгөвөрийн лаборатори', href: 'ediin-usguvur' },
          { name: 'Малын тэжээлийн шингэцийн лаборатори', href: 'tejeeliin-shingets' },
          { name: 'Хөрс судлалын лаборатори', href: 'hurs' },
        ]
      }
    ]
  },
  {
    name: 'Салбар, судалгааны төвүүд',
    submenu: [
      {
        name: 'Салбар хүрээлэн, станц', submenu: [
          { name: 'Говийн бүсийн бэлчээрийн МААЭШХ', href: 'goviin-bus' },
          { name: 'Дорнод бүсийн ХААСЭШҮХ', href: 'dornod-bus' },
          { name: 'Өндөр уулын бүсийн ХААЭШ-ний станц', href: 'uuliin-bus' },
          { name: 'Төв, Батсүмбэр дэх ЗААСЭШ-ний станц', href: 'tuv-bus' },
        ]
      },
      {
        name: 'Судалгаа үйлдвэрлэлийн төвүүд', submenu: [
          { name: 'Дархан-Уул, Хонгор дахь туршилт судалгааны төв', href: 'khongor-tuv' },
          { name: 'Төв, Батсүмбэр дэх туршилтын төв', href: 'batsumber-tuv' },
          { name: 'Төв, Архуст дахь судалгааны төв', href: '' },
          { name: 'Төв, Баяндэлгэр дэх судалгааны төв', href: '' },
          { name: 'Тэжээлийн туршилт үйлдвэрлэлийн цех', href: '' },
          { name: 'Туршилт, судалгааны хүлэмж', href: '' },

        ]
      },
    ]
  },
  { name: 'Төсөл хөтөлбөр', href: '/Төсөл хөтөлбөр' },
  { name: 'Хамтын ажиллагаа', href: '/Хамтын ажиллагаа' },
  {
    name: 'Бүтээгдэхүүн',
    submenu: [
      { name: 'Малын тэжээл', href: 'uusgen-baguulagch' },
      { name: 'Тэжээлийн олон наст ургамлын сортууд', href: 'uusgen-baguulagch' },
      { name: 'Малын үүлдэр, омог', href: 'uusgen-baguulagch' },
    ]
  },
  {
    name: 'Татац',
    submenu: [
      { name: 'Гэрээ', href: 'uusgen-baguulagch' },
      { name: 'Дүрэм', href: 'uusgen-baguulagch' },
      { name: 'Журам', href: 'uusgen-baguulagch' },
      { name: 'Өгүүлэл нийтлүүлэх', href: 'uusgen-baguulagch' },
      { name: 'Стандарт', href: 'uusgen-baguulagch' },
      { name: 'Тогтоол', href: 'uusgen-baguulagch' },
      { name: 'Тушаал', href: 'uusgen-baguulagch' },
      { name: 'Хууль', href: 'uusgen-baguulagch' },
    ]
  },
  { name: 'Холбоос', href: '/contact-us', hide: true },
  { name: 'Шилэн данс', href: '/contact-us', hide: true },
  { name: 'Холбоо барих', href: '/contact-us', hide: true },
]

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


  return (
    <header className='shadow bg-white'>
      <nav className="hidden mx-auto lg:flex max-w-7xl items-center justify-between h-[80px] border-b border-gray-900/10" aria-label="Global">
        <Link to={'/'} className="flex">
          <img className="h-[70px] w-auto" src="/mecore-logos/main-logo/logo.png" alt="Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн" />
          <div
            style={{ lineHeight: '1' }}
            className='text-base font-extrabold sm:text-lg text-primary-900 ml-4 text-center flex items-center'>
            Мал Аж Ахуйн Эрдэм <br />Шинжилгээний Хүрээлэн
          </div>
        </Link>
        <div className='hidden lg:flex lg:gap-x-6 text-sm font-bold items-center leading-none text-center'>
          <Link
            to={'/contact-us'}
            className="py-1 text-gray-700 hover:text-primary-900">
            {'Холбоос'}
          </Link>
          <Link
            to={'/contact-us'}
            className="py-1 text-gray-700 hover:text-primary-900">
            {'Шилэн данс'}
          </Link>
          <Link
            to={'/contact-us'}
            className="py-1 text-gray-700 hover:text-primary-900">
            {'Холбоо барих'}
          </Link>
        </div>
      </nav>
      <div className="sticky top-0 z-40">
        <nav className="mx-auto flex max-w-7xl items-center justify-center p-1 lg:px-8" aria-label="Global">
          <div className="flex lg:hidden">
            <Link to={'/'} className="flex mr-8">
              <img className="h-[50px] w-auto" src="/mecore-logos/main-logo/logo.png" alt="Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн" />
              <div
                style={{ lineHeight: '1' }}
                className='text-base font-extrabold sm:text-lg text-primary-900 ml-4 text-center flex items-center'>
                Мал Аж Ахуйн Эрдэм <br />Шинжилгээний Хүрээлэн
              </div>
            </Link>
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-5 text-sm font-bold items-center leading-none text-center">
            {navigation.map((item) => {
              if (item.hide) {
                return <></>
              }
              if (item.href) {
                return <Link
                  key={item.name}
                  to={item.href}
                  className="py-1 text-gray-700 hover:text-primary-900">
                  {item.name}
                </Link>
              } else {
                return <div className="group inline-block py-3" key={item.name}>
                  <div className="outline-none hover:cursor-pointer focus:outline-none py-1 bg-white rounded-sm flex items-center text-gray-700 hover:text-primary-900">
                    <span>{item.name}</span>
                    <span>
                      <svg className="fill-current h-4 w-4 transform group-hover:-rotate-180
        transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </span>
                  </div>
                  <ul className="transform scale-0 group-hover:scale-100 absolute mt-3 text-sm font-semibold
                    transition duration-300 ease-in-out origin-top w-[230px] shrink bg-white leading-6 
                    text-gray-900 shadow-lg ring-gray-900/5">
                    {item.submenu.map((subitem) => {
                      if (subitem.submenu) {
                        return <li className="rounded-sm relative px-4 py-2 hover:text-primary-900 hover:bg-slate-100" key={subitem.name}>
                          <button className="w-full text-left flex items-center outline-none focus:outline-none">
                            <span className="pr-1 flex-1">{subitem.name}</span>
                            <span className="mr-auto">
                              <svg className="fill-current h-4 w-4
                transition duration-150 ease-in-out" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </span>
                          </button>
                          <ul className="bg-white absolute top-0 right-0 
                          transition duration-150 ease-in-out origin-top-left
                          min-w-32 shrink w-56 text-left"
                          >
                            {subitem.submenu.map((subitemchild) => (
                              <li className="block text-gray-900 hover:bg-slate-100" key={subitemchild.name}>
                                <Link to={subitemchild.href} className='hover:text-primary-900 block w-full py-2 px-4'>
                                  {subitemchild.name}
                                </Link>
                              </li>
                            ))}
                          </ul>

                        </li>
                      } else {
                        return <li
                          className="block hover:bg-slate-100 hover:cursor-pointer w-full text-left"
                          key={subitem.name}>
                          <Link to={subitem.href} className='block py-2 px-4 w-full hover:text-primary-900'>
                            {subitem.name}
                          </Link>
                        </li>
                      }
                    })}
                  </ul>
                </div>
              }
            })}
          </div>
        </nav>
        <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <Link to={'/'} className="flex">
                <span className="sr-only">Your Company</span>
                <img className="h-[50px] w-auto" src="/mecore-logos/main-logo/logo.png" alt="Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн" />
                <div
                  style={{ lineHeight: '1' }}
                  className='text-base font-extrabold sm:text-lg text-primary-900 ml-4 text-center leading-4 flex items-center'>
                  Мал Аж Ахуйн Эрдэм <br />Шинжилгээний Хүрээлэн
                </div>
              </Link>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => {
                    if (item.submenu) {
                      return <Disclosure as="div" key={item.name}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button
                              className={classNames(
                                item.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                'flex items-center w-full text-left rounded-md p-2 gap-x-3 text-sm leading-6 font-semibold text-gray-700'
                              )}
                            >
                              {item.name}
                              <ChevronRightIcon
                                className={classNames(
                                  open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                  'ml-auto h-5 w-5 shrink-0'
                                )}
                                aria-hidden="true"
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel as="ul" className="mt-1 px-2">
                              {item.submenu.map((subItem) => (
                                <li key={subItem.name}>
                                  {subItem.submenu ? (
                                    <Disclosure as="div">
                                      {({ open }) => (
                                        <>
                                          <Disclosure.Button
                                            className={classNames(
                                              subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                              'flex items-center w-full text-left rounded-md p-2 pl-9 gap-x-3 text-sm leading-6 text-gray-700 hover:text-primary-900 font-medium'
                                            )}
                                          >
                                            {subItem.name}
                                            <ChevronRightIcon
                                              className={classNames(
                                                open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                                'ml-auto h-5 w-5 shrink-0'
                                              )}
                                              aria-hidden="true"
                                            />
                                          </Disclosure.Button>
                                          <Disclosure.Panel as="ul" className="mt-1 px-2">
                                            {subItem.submenu.map((subItem1) => (
                                              <li key={subItem1.name}>
                                                {/* 44px */}
                                                <Link
                                                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                                                  to={subItem1.href}
                                                  className={classNames(
                                                    subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                                    'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 hover:text-primary-900 font-medium'
                                                  )}
                                                >
                                                  {subItem1.name}
                                                </Link>
                                              </li>
                                            ))}
                                          </Disclosure.Panel>
                                        </>
                                      )}
                                    </Disclosure>
                                  ) : (
                                    <Link
                                      to={subItem.href}
                                      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                                      className={classNames(
                                        subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                        'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700 hover:text-primary-900 font-medium'
                                      )}
                                    >
                                      {subItem.name}
                                    </Link>
                                  )}

                                </li>
                              ))}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    } else {
                      return <Link
                        to={item.href}
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        className="block rounded-md p-2 text-sm leading-6 font-semibold text-gray-700 hover:text-primary-900"
                        key={item.name}
                      >
                        {item.name}
                      </Link>
                    }

                  })}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>

      </div>
    </header >
  )
}

export default Header