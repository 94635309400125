import React from 'react'
import BlogHeader from '../../BlogHeader'

const KhongorTuv = () => {
    return (
        <div>
            <BlogHeader title={'khongor-tuv'} />
            <div className="mx-auto max-w-7xl py-8 md:gap-4 bg-white px-8">
                <div className="flex flex-wrap justify-center items-center my-4">
                    {/* <img
                        width={120}
                        src="/background-image/riah/Picture58.jpg"
                        alt="ДАРХАН-УУЛ АЙМГИЙН ХОНГОР СУМ ДАХЬ СУДАЛГААНЫ СУУРИН"
                    /> */}
                    <div className='text-center text-primary-700 uppercase font-semibold p-3 max-w-xl'>
                        ДАРХАН-УУЛ АЙМГИЙН ХОНГОР СУМ ДАХЬ СУДАЛГААНЫ СУУРИН
                    </div>
                </div>
                <hr className='my-6' />
                <div className='text-justify text-gray-700 text-sm font-medium'>
                    <p>
                        Дархан-Уул аймгийн Хонгор сум дахь туршилт, судалгааны
                        төвийн дэргэд Алтанбулаг үржлийн хэсэг, нарийн, нарийвтар
                        ноост Хангай, Ерөө, Орхон үүлдрийн хонины үржлийн цөм
                        сүргийг өсгөн үржүүлж байна. Туршилт судалгааны талбайд
                        малын тэжээлийн нэг наст ургамлыг тэжээл болон үр үржүүлгийн
                        чиглэлээр тариалж байна.

                    </p>
                </div>
                <div className='flex flex-wrap items-center mx-auto my-6'>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture62.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={"Нарийн нарийвтар ноост Хангай, Ерөө үүлдрийн хонь"} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Нарийн нарийвтар ноост Хангай, Ерөө үүлдрийн хонь

                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture63.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Алтанбулаг үүлдрийн хэсгийн хонь

                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture64.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Судлаачид вандуйны
                            туршилтын талбайд
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture65.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Ногоон тэжээлээр
                            дарш бэлтгэж байгаа нь

                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture66.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Ногоон тэжээлээр
                            дарш бэлтгэж байгаа нь

                        </figcaption>
                    </figure>
                </div>
                <hr className='my-6' />
            </div>
        </div>
    )
}

export default KhongorTuv