import React from 'react'
import BlogHeader from '../BlogHeader'

const AboutUs = () => {
    return (
        <div>
            <BlogHeader title={'aboutus'} />
            <div className="mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-3 my-8 md:gap-4 bg-white">
                <div>
                    <iframe
                        title='Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн'
                        width="100%"
                        height="394"
                        src="https://www.youtube-nocookie.com/embed/b0aZyjLW3IA?autoplay=1&amp;feature=oembed&amp;controls=0&amp;hd=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;enablejsapi=0&amp;enablejsapi=1"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen=""
                        data-uk-video=""
                    ></iframe>
                </div>
                <div className="col-span-2 px-2">
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        Ж.САМБУУГИЙН НЭРЭМЖИТ МАЛ АЖ АХУЙН ЭРДЭМ ШИНЖИЛГЭЭНИЙ ХҮРЭЭЛЭН
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>
                        <p ><strong>Товч түүх: </strong></p>
                        <ul className='list-disc pl-8'>
                            <li>1961 онд ШУА-ийн Хөдөө Аж Ахуйн хүрээлэн</li>
                            <li>1963 онд ШУА-ийн Мал Аж Ахуйн Хүрээлэн</li>
                            <li>1971 онд Ж.Самбуугийн&nbsp;нэрэмжит болгож</li>
                            <li>1977 онд Ж.Самбуугийн&nbsp;нэрэмжит Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн хэмээн нэрлэх болсон</li>
                        </ul>
                        <p ><strong>Эрхэм зорилго: </strong></p>
                        <p >Шинжлэх ухаан, үйлдвэрлэл, оюуны бизнесийн цогцолбор болж хөгжинө</p>
                        <p ><strong>Байгууллагын чиг үүрэг: </strong></p>
                        <ul className='list-disc pl-8'>
                            <li>Малын биологийн нөөцийг судлах, генофондыг хамгаалах, генетик, селекци, биотехнологийн аргаар бүтээмж өндөртэй мал бий болгох</li>
                            <li>Уур амьсгалын өөрчлөлтийг даван туулах чадавхитай, ашиг шимийн чиглэлээр төрөлжсөн үүлдрийн малын тэжээл норм, жор турших, тэжээллэгийн технологи боловсруулах</li>
                            <li>Бэлчээр хадланг зохистой ашиглах, сайжруулах, бүтээмжийг нэмэгдүүлэх арга технологи боловсруулах</li>
                            <li>Тэжээлийн олон наст ургамлын генфондыг хамгаалах, ган, хүйтэнд тэсвэртэй тэжээлийн ургамлын сорт бий болгох, үр үржүүлэх</li>
                        </ul >
                        <p ><strong>Салбар хүрээлэн, станц: </strong></p>
                        <ul className='list-disc pl-8'>
                            <li>Өндөр уулын бүсийн ХААЭШ-ний станц</li>
                            <li>Говийн бүсийн бэлчээрийн МААЭШХ</li>
                            <li>Дорнод бүсийн ХААСЭШХ</li>
                            <li>Батсүмбэр дэх ЗААСЭШ-ний станц</li>
                        </ul>
                        <p ><strong>Судалгаа үйлдвэрлэлийн төвүүд: </strong></p>
                        <ul className='list-disc pl-8'>
                            <li>Дархан-Уул, Хонгор дах судалгааны төв</li>
                            <li>Төв, Батсүмбэр дэх судалгааны төв</li>
                            <li>Архустын судалгааны төв</li>
                            <li>Хархорины судалгааны төв</li>
                            <li>Тэжээл үйлдвэрлэл цех</li>
                        </ul>
                        <p ><strong>Лаборатори</strong></p>
                        <ul className='list-disc pl-8'>
                            <li>Мал аж ахуйн салбарын судалгааны 6</li>
                            <li>Бэлчээр, тэжээлийн салбарын судалгааны 6 нийт 12 лабораторитой.</li>
                        </ul>
                        <p ><strong>&nbsp;</strong><strong>Бидний амжилт: </strong></p>
                        <p >Тус хүрээлэнгийн эрдэмтэдийн удирдлага, шууд оролцоотойгоор арвин ашиг шимт малын үүлдэр, үүлдрийн болон үржлийн хэсэг 29, омог 17, хэвшил 1, удам 30, тэжээлийн ургамалын 10 сорт бий болж, үйлдвэрлэлд нэвтрүүлсэн. Мөн судалгаа шинжилгээний үр дүнд 200 орчим ном товхимол, бүтээл, судалгааны 500 гаруй тайлан бичиж хэвлүүлснээс гадна технологи, заавар, зөвлөмж, стандарт, техникийн нөхцөл 420 гаруй, шинэ бүтээл, патент 100 гаруйг боловсруулж баталгаажуулсан.</p>
                    </div>
                    <hr className='my-6' />
                </div>
            </div>
        </div>
    )
}

export default AboutUs