import React from 'react'
import BlogHeader from './BlogHeader'

const TusulHutulbur = () => {
    return (
        <div>
            <BlogHeader title={'Төсөл хөтөлбөр'} />
            <div className='mx-auto max-w-7xl my-3 space-y-4'>
                <img src="/image/Төсөл хөтөлбөр/01/07-Myagmarsuren-poster-2023.10.25-72PiInch.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/02/01-04-Nango_noos-2023.11.10.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/02/02-04-Nango_nekhii-2023.11.10.png" alt="" />

                <img src="/image/Төсөл хөтөлбөр/03/04-Bolormaa-Doroitson belcheer-2023.10.25.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/04/07-Dejidmaa-Zugshruulekh-2023.11.01.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/05/05-Khishigsuren-2023.11.07.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/06/07-Urankhaich-metan-2023.10.25.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/07/05-Gantuya-poster-2023.10.25.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/08/02-Beisen-Saam-2023.10.25.png" alt="" />
                <img src="/image/Төсөл хөтөлбөр/08/03-Beisen-Tejeel-2023.10.30.png" alt="" />
            </div>
        </div>

    )
}

export default TusulHutulbur