import React, { Suspense } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import NotFound from '../layouts/error/NotFound'
import PrivateRoute from './PrivateRoute'
import Home from '../pages/Home'
import ContactUs from '../pages/main/ContactUs'
import ZahiralMendchilgee from '../pages/main/ZahiralMendchilgee'
import AboutUs from '../pages/main/AboutUs'
import NoosNooluur from '../pages/main/Laborator/NoosNooluur'
import Genetic from '../pages/main/Laborator/Genetic'
import Biotechnolog from '../pages/main/Laborator/Biotechnolog'
import Mah from '../pages/main/Laborator/Mah'
import Suu from '../pages/main/Laborator/Suu'
import Genopond from '../pages/main/Laborator/Genopond'
import Belcheer from '../pages/main/Laborator/Belcheer'
import Tejeel from '../pages/main/Laborator/Tejeel'
import Urgamalgeopond from '../pages/main/Laborator/Urgamalgeopond'
import Ediinusguvur from '../pages/main/Laborator/Ediinusguvur'
import TejeeliinShingets from '../pages/main/Laborator/TejeeliinShingets'
import Hurs from '../pages/main/Laborator/Hurs'
import GoviinBus from '../pages/main/Bus/GoviinBus'
import DornodBus from '../pages/main/Bus/DornodBus'
import UuliinBus from '../pages/main/Bus/UuliinBus'
import TuviinBus from '../pages/main/Bus/TuviinBus'
import KhongorTuv from '../pages/main/Tuv/KhongorTuv'
import BatsumberTuv from '../pages/main/Tuv/BatsumberTuv'
import TusulHutulbur from '../pages/TusulHutulbur'
import HamtiinAjillagaa from '../pages/HamtiinAjillagaa'

const loadingComp = <div className="k-loading-mask" style={{ zIndex: 100000 }}>
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
</div>

export default function CoreRoutes() {
    return (
        <BrowserRouter>
            <Suspense fallback={loadingComp}>
                <Routes>
                    <Route exact path='/' element={<PrivateRoute />}>
                        <Route exact path='/' element={<Home />} />
                        <Route path='/contact-us' element={<ContactUs />} />
                        <Route path='/zahiral-mendchilgee' element={<ZahiralMendchilgee />} />
                        <Route path='/bidnii-tuhai' element={<AboutUs />} />
                        <Route path='/noos-nooluur' element={<NoosNooluur />} />
                        <Route path='/genetic' element={<Genetic />} />
                        <Route path='/biotechnolog' element={<Biotechnolog />} />
                        <Route path='/mah' element={<Mah />} />
                        <Route path='/suu' element={<Suu />} />
                        <Route path='/genopond' element={<Genopond />} />
                    
                        <Route path='/belcheer' element={<Belcheer />} />
                        <Route path='/tejeel' element={<Tejeel />} />
                        <Route path='/urgamal-geopond' element={<Urgamalgeopond />} />
                        <Route path='/ediin-usguvur' element={<Ediinusguvur />} />
                        <Route path='/tejeeliin-shingets' element={<TejeeliinShingets />} />
                        <Route path='/hurs' element={<Hurs />} />

                        <Route path='/goviin-bus' element={<GoviinBus />} />
                        <Route path='/dornod-bus' element={<DornodBus />} />
                        <Route path='/uuliin-bus' element={<UuliinBus />} />
                        <Route path='/tuv-bus' element={<TuviinBus />} />

                        <Route path='/khongor-tuv' element={<KhongorTuv />} />
                        <Route path='/batsumber-tuv' element={<BatsumberTuv />} />

                        <Route path='/Төсөл хөтөлбөр' element={<TusulHutulbur />} />
                        <Route path='/Хамтын ажиллагаа' element={<HamtiinAjillagaa />} />
                    </Route>
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
