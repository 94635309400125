import React from 'react'
import ComponentLaborator from './ComponentLaborator';

const Urgamalgeopond = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture30.jpg",
            description: "Лабораторийн судлаачид"
        },
        {
            imgpath: "/background-image/riah/Picture31.jpg",
            description: "Байгалийн ургамлын үр цуглуулах"
        },
        {
            imgpath: "/background-image/riah/Picture32.jpg",
            description: "Үрийн чанар тодорхойлох"
        },
        {
            imgpath: "/background-image/riah/Picture33.jpg",
            description: "Сортын үр үржүүлгийн талбай"
        },
    ];

    const mainservice = 'Монгол орны тэжээлийн ач холбогдолтой ургамлын үрийн генийн санг хамгаалан  баяжуулах, ган, хүйтэнд тэсвэртэй таримал хэлбэр, сорт бий болгох, сайн чанарын үр үйлдвэрлэх, үрийн чанарыг тодорхойлох'
    const chiglel = [
        'Монгол орны тэжээлийн олон наст ургамлын генийн санг баяжуулж, шинэчлэх;',
        'Тэжээлийн ургамлын генийн санд хадгалагдаж байгаа нэг болон олон наст ургамлын үрийн дээжид аж ахуй, биологийн үнэлгээ өгөх, үрийн чанарыг тодорхойлох, үрийг шинэчлэх;',
        'Тэжээлийн ач холбогдолтой, тарималжих, нутагших  ирээдүйтэй ургамлыг сонгон шалгаруулах, таримал хэлбэр, сорт бий болгох;',
        'Тарималжиж, нутагшсан сортуудыг тариалах агротехник боловсруулах, супер элит үр үйлдвэрлэх;',
        'Тэжээлийн ургамал тариалах, технологи, стандарт заавар зөвлөмж боловсруулах;',

    ];
    return (
        <ComponentLaborator
            title={'urgamal-geopond'}
            photos={photos}
            maintitle={'ТЭЖЭЭЛИЙН УРГАМЛЫН ГЕНОФОНД, ТАРИМАЛ ТЭЖЭЭЛ СУДЛАЛЫН ЛАБОРАТОРИ'}
            mainservice={mainservice}
            chiglel={chiglel}
        />
    )
}

export default Urgamalgeopond