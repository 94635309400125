import React from 'react'
import BlogHeader from '../../BlogHeader'

const UuliinBus = () => {
    return (
        <div>
            <BlogHeader title={'uuliin-bus'} />
            <div className="mx-auto max-w-7xl py-8 md:gap-4 bg-white px-8">
                <div className="flex flex-wrap justify-center items-center my-4">
                    <img
                        width={120}
                        src="/background-image/riah/Picture55.jpg"
                        alt="АРХАНГАЙ АЙМАГ ДАХЬ ӨНДӨР УУЛЫН БҮСИЙН ХӨДӨӨ АЖ АХУЙН ЭРДЭМ ШИНЖИЛГЭЭ-ҮЙЛДВЭРЛЭЛИЙН СТАНЦ"
                    />
                    <div className='text-center text-primary-700 uppercase font-semibold p-3 max-w-xl'>
                        АРХАНГАЙ АЙМАГ ДАХЬ ӨНДӨР УУЛЫН БҮСИЙН ХӨДӨӨ АЖ АХУЙН ЭРДЭМ ШИНЖИЛГЭЭ-ҮЙЛДВЭРЛЭЛИЙН СТАНЦ
                    </div>
                </div>
                <hr className='my-6' />
                <div className='text-justify text-gray-700 text-sm font-medium'>
                    <p className='my-3'>
                        Өндөр уулын бүсийн малын үржил-ашиг шимт чанар, бэлчээр,
                        тэжээлийн асуудлыг судалж, үр ашигтай дэвшилтэд технологи
                        боловсруулах, үүлдэр, омог, сорт гаргаж үйлдвэрлэлд
                        нэвтрүүлэх зорилготой.
                    </p>
                    <p className='my-3'>
                        <strong>Товч түүх: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>1988 онд Хангайн Өндөр Уулын бүсийн Мал Аж Ахуйн Эрдэм Шинжилгээний станц нэртэйгээр байгуулагдсан.</li>
                        <li>2015 онд Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэнгийн салбар, Архангай аймаг дахь Өндөр Уулын бүсийн Хөдөө Аж Ахуйн эрдэм шинжилгээний станц.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Үйл ажиллагааны үндсэн чиглэл: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Махлаг ба сүүлэг хэвшлийн сарлагийн цөм сүрэг бий болгох </li>
                        <li>Сарлагийн үржлийн хээлтэгч, хээлтүүлэгч бойжуулах, шилмэл хээлтүүлэгчийн үр гүн хөлдөөх, хадгалах</li>
                        <li>Сарлагийн хөөврийг бэлтгэн бүтээгдэхүүн үйлдвэрлэх технологи боловсруулах, үйлдвэрлэлд нэвтрүүлэх</li>
                        <li>Нутгийн хонь, ямааны үүлдэрлэг байдал, ашиг шимийг нэмэгдүүлэх</li>
                        <li>Сүү цагаан идээ боловсруулах технологи</li>
                        <li>Байгалийн бэлчээрийг зүй зохистой ашиглах шинжлэх ухааны үндэслэл боловсруулах </li>
                        <li>Доройтсон бэлчээр, хадланг тордон сайжруулах </li>
                        <li>Тэжээлийн нэг, олон наст ургамлыг сонгон шалгаруулах, үр үржүүлэх</li>
                        <li>Багсармал болон эрдэс тэжээл үйлдвэрлэх</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Судалгааны үр дүнгээс: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Монгол сарлагийн үүлдэр, Тамир үүлдрийн хэсгийн хонь, Хотонт үржлийн хэсгийн хонь,  саман ерхөгийн Чулуут, соргүй согооврын Тамир сорт зэргийг батлуулсан.</li>
                        <li>Хангайн өндөр уулын бүсийн бэлчээрийн бүтээмжийг нэмэгдүүлэх, зохистой ашиглах үндэслэлийг бий болгосон.</li>
                        <li>Төвөдийн зэрлэг сарлагийн ичмэл үрээр Монгол сарлагийг сайжруулж, сарлагийн бухны ичээл үр бий болгон, сарлагийн үнээнд олон өндгөн эс үүсгэсэн туршилт, хайнаг төст гибрид бий болгох ажлуудыг гүйцэтгэсэн.</li>
                        <li>Архангай аймгийн 8 суманд үхэр, хонины ашиг шимийг сайжруулах үржлийн ажлын оновчтой менежмент нэвтрүүлэх ажил, Монгол үндэсний шилдэг брэнд бүтээгдэхүүн Тамирын цагаан ааруул буй болгож, малын ашигт долооц үйлдвэрлэл зэрэг олон бүтээлч ажлыг хэрэгжүүлэн ажиллаж байна.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Үйлдвэрлэлд туршин нэвтэрсэн ажил: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Сарлагийн сүүгээр хийсэн, үндэсний брэнд бүрээгдэхүүн - Сүүн хурууд</li>
                        <li>Малын төрөл, ашиг шимийн онцлогт тохирсон жороор эрдсийг шахмал тэжээл үйлдвэрлэж байна</li>
                        <li>Сарлагийн хөөврийг самнаж бэлтгэх, бүтээгдэхүүн үйлдвэрлэх технологийн туршилт хийгдэж байна</li>
                        <li>Үржлийн өсвөр хээлтүүлэгч, хээлтэгч бойжуулж борлуулах. Ашиг шимээр шилэн сонгосон сарлаг, “Тамир” хонины үржлийн хуц, Улаан ямааны өсвөр ухна бойжуулж Архангай, Хөвсгөл аймгийн сумдад захиалгаар борлуулж байна. </li>
                    </ul>
                </div>
                <div className='flex flex-wrap items-center mx-auto my-6'>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture56.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Арын эгнээний зүүн гараас Отгонбаатар, Дагдан, Д.Долгор, Б.Лхагвасүрэн, Ш.Оюунжаргал, Баяраа, урд эгнээний зүүн гар талаас В.Дагвийхорол, Н.Лхагважав, С.Шархүү, Ч.Гомбожав (1998)
                        </figcaption>
                    </figure>
                    <figure className="mb-4 inline-block max-w-sm mx-auto">
                        <img
                            src={"/background-image/riah/Picture57.jpg"}
                            width={"354"}
                            className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                            alt={""} />
                        <figcaption className="text-sm text-neutral-600 dark:text-neutral-400 text-center">
                            Зүүн гар талаас Ч.Мөнхбат, Ч.Гомбожав, Д.Дэлгэрмаа, Л.Отгонтуяа, Ш.Оюунжаргал, П.Батболд, М.Одбаяр

                        </figcaption>
                    </figure>
                </div>
                <hr className='my-6' />
            </div>
        </div>
    )
}

export default UuliinBus