import React from 'react'
import ComponentLaborator from './ComponentLaborator';

const Tejeel = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture26.jpg",
            description: "Тэжээлийн найрлага дахь уураг тодорхойлж байгаа нь"
        },
        {
            imgpath: "/background-image/riah/Picture27.jpg",
            description: "Тэжээлийн эрдэс бодис тодорхойлж байгаа нь"
        },
        {
            imgpath: "/background-image/riah/Picture28.jpg",
            description: "Эрдсийн баяжмал долооц"
        },
        {
            imgpath: "/background-image/riah/Picture29.jpg",
            description: "Малын  тэжээлийн 10 гаруй төрлийн балансжуулсан тэжээл"
        },
    ];
    
    const mainservice = 'Төрөл бүрийн малын тэжээл, тэжээлийн түүхий эд, бэлчээрийн ургамал, ногоон тэжээлийн химийн найрлага, макро, микро элементүүдийн агууламжийг тогтооно.Мөн улсын хэмжээнд хэрэглэгддэг малын бүх төрлийн тэжээлд чанарын хяналт тавих шинжилгээний аргазүйг боловсруулах, тэжээл үйлдвэрлэгчдийн хүсэлтээр тэжээлийн чанарыг тодорхойлж, зөвлөгөө өгөх чиглэлээр ажиллаж байна.'
    const chiglel = [
        'Малын тэжээлийн ургамлын химийн найрлагыг тодорхойлох;',
        'Төрөл бүрийн малын болон гахай, тахианд зориулсан үйлдвэрийн тэжээлийн чанарыг тогтоох;',
        'Малын тэжээлийн дутагдлыг нөхөх түүхий эдийн судалгаа хийх;',
        'Төрөл бүрийн малын болон гахай, тахианд зориулсан  тэжээлийн балансжуулсан жор боловсруулах;',
    ];
    return (
        <ComponentLaborator
            title={'tejeel'}
            photos={photos}
            maintitle={'МАЛЫН ТЭЖЭЭЛИЙН ҮНЭЛГЭЭНИЙ ЛАБОРАТОРИ'}
            mainservice={mainservice}
            chiglel={chiglel}
        />
    )
}

export default Tejeel