import React from 'react'
import BlogHeader from '../BlogHeader'
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'

const ContactUs = () => {
  return (
    <div>
      <BlogHeader title={'Холбоо барих'} />
      <div className='mx-auto max-w-7xl p-3 space-y-4 bg-white'>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <div>
            <div className='text-3xl font-bold tracking-tight'>Холбогдох хаяг:</div>
            <dl className="mt-10 space-y-4 text-base leading-7">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Address</span>
                  <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  Улаанбаатар хот, Хан-Уул дүүрэг, 11-р хороо, Зайсангийн гудамж, Мал Аж ахуйн эрдэм шинжилгээний хүрээлэн
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Утас:</span>
                  <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-white" href="tel:976-11-341936">
                    976-11-341936
                  </a>
                  <br />
                  <a className="hover:text-white" href="tel:976-11-341572">
                    976-11-341572
                  </a>
                  <br />
                  <a className="hover:text-white" href="tel:976-11-341572">
                    976-11-341518
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">И-мэйл:</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-white" href="mailto:hello@example.com">
                    info@riah.mn
                  </a>
                </dd>
              </div>
            </dl>
          </div>
          <div>
            <div className='text-3xl font-bold tracking-tight'>Contact:</div>
            <dl className="mt-10 space-y-4 text-base leading-7">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <BuildingOffice2Icon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  Mongolia, UB, Khan-Uul district, 11th khoroo, Zaisan street, 
                  Research institute of animal husbandry
                  P.O.BOX-04, P.O-53, UB 17024, MONGOLIA
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Tel:</span>
                  <PhoneIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-white" href="tel:976-11-341936">
                    976-11-341936
                  </a>
                  <br />
                  <a className="hover:text-white" href="tel:976-11-341572">
                    976-11-341572
                  </a>
                  <br />
                  <a className="hover:text-white" href="tel:976-11-341572">
                    976-11-341518
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">E-mail:</span>
                  <EnvelopeIcon className="h-7 w-6 text-gray-400" aria-hidden="true" />
                </dt>
                <dd>
                  <a className="hover:text-white" href="mailto:hello@example.com">
                    info@riah.mn
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
          <img src="/image/Холбоо барих хаяг/GoogleMap.png" alt="GoogleMap" />
          <img src="/image/Холбоо барих хаяг/Riah_contact_orj.jpg" alt="Riah_contact_orj" />
        </div>
      </div>
    </div>
  )
}

export default ContactUs