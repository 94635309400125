import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../layouts/Header'
import Footer from '../layouts/Footer';
import { useTranslation } from 'react-i18next';

const PrivateRoute = () => {
    const { t } = useTranslation()
    // Smooth scroll to top
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        const scrollToTopButton =
            document.getElementById('scroll-to-top');

        // Show button when user scrolls down
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 100) {
                scrollToTopButton.style.display = 'block';
            } else {
                scrollToTopButton.style.display = 'none';
            }
        });


    }, [])
    return <div className='bg-bg_pattern min-h-screen'>
        <Header />
        <button onClick={scrollToTop}
            id="scroll-to-top"
            title={t('pageup')}
            className="fixed bottom-4 right-4 z-50
                       rounded-full bg-primary-700 p-3 text-xs font-medium uppercase leading-tight 
                       text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-800 
                       hover:shadow-lg focus:bg-primary-800 focus:shadow-lg focus:outline-none focus:ring-0 
                       active:bg-primary-800 active:shadow-lg"
        >
            <span className="[&>svg]:w-4">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 10.5 12 3m0 0 7.5 7.5M12 3v18" />
                </svg>
            </span>
        </button>
        <Outlet />
        <Footer />
    </div>;
}

export default PrivateRoute
