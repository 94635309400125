import React from 'react'
import BlogHeader from '../../BlogHeader'

const Suu = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture19.jpg",
            description: "Сүүний химийн найрлага тодорхойлж байгаа нь"
        },
        {
            imgpath: "/background-image/riah/Picture20.jpg",
            description: "“Сүү судлалын лаборатори”-ийн хамт олон"
        },
    ];
    return (
        <div>
            <BlogHeader title={'suu'} />
            <div className="mx-auto max-w-7xl my-8 md:gap-4 bg-white p-4">
                <div>
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        СҮҮ СУДЛАЛЫН ЛАБОРАТОРИ
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>

                        <p><strong>Үндсэн үйл ажиллагаа: </strong></p>
                        <p>
                            Бүх төрлийн малын сүү, сүүн бүтээгдэхүүний чанар найрлага, технологийн горимыг судлан тогтоож, бүтээгдэхүүний цэвэр өсгөвөр гаргах туршилт судалгааны ажил хийх

                        </p>
                        <p className='mt-6'><strong>Судалгааны чиглэл: </strong></p>
                        <ul className='list-disc pl-8'>
                            <li>Таван хошуу малын сүүний хими, физикийн шинж чанар,  саамын гарцыг, саалийн хугацаагаар тогтоох,  </li>
                            <li>Малын гаралтай сүүн бүтээгдэхүүний технологи горимын судалгаа,  </li>
                            <li>Сүүн бүтээгдэхүүний цэвэр өсгөвөр гарган авч генийн санд бүртгүүлэх </li>
                        </ul>
                    </div>
                    <hr className='my-6' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-auto'>
                    {photos.map((item) => {
                        return <figure className="mb-4 inline-block max-w-sm mx-auto">
                            <img
                                src={item.imgpath}
                                width={"354"}
                                className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                                alt={item.description} />
                            <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                                {item.description}
                            </figcaption>
                        </figure>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Suu