import React, { useEffect, useState } from 'react'
import { Carousel } from '../components/features';

let slides = [
    {
        img: "/background-image/riah/Picture1.jpg",
        title: ""
    },
    {
        img: "/background-image/riah/Picture2.jpg",
        title: "Мал аж ахуй, бэлчээр тэжээлийн чиглэлээр шинжлэх ухаан, үйлдвэрлэл, оюуны бизнесийн үйл ажиллагааг хослон эрхэлдэг үндэсний хэмжээний эрдмийн цогцолбор болж,  мал аж ахуйн тогтвортой хөгжилд бодитой хувь нэмэр оруулах юм."
    },
    {
        img: "/background-image/riah/Picture3.jpg",
        title: "Шинжлэх ухаан, үйлдвэрлэл, оюуны бизнесийн цогцолбор болж хөгжинө"
    }
];

const stats = [
    { id: 1, name: 'Шинжлэх ухааны доктор', value: '22' },
    { id: 2, name: 'Монголын  шинжлэх ухааны академийн гишүүн', value: '8' },
    { id: 3, name: 'Хөдөө аж ахуйн шинжлэх ухааны академийн гишүүн', value: '20' },
    { id: 4, name: 'Төрийн шагналт', value: '8' },
    { id: 5, name: 'Гавьяат', value: '24' },
]



const Home = () => {
    const words = ["“ХАНТАЙШИР-ЭРДЭМ”"];
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentWord, setCurrentWord] = useState('');
    const [indexInWord, setIndexInWord] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const type = () => {
            const word = words[currentIndex];
            if (isDeleting) {
                setCurrentWord(word.substring(0, indexInWord - 1));
                setIndexInWord(prevIndex => prevIndex - 1);
                if (indexInWord === 0) {
                    setIsDeleting(false);
                    setCurrentIndex(prevIndex => (prevIndex + 1) % words.length);
                }
            } else {
                setCurrentWord(word.substring(0, indexInWord + 1));
                setIndexInWord(prevIndex => prevIndex + 1);
                if (indexInWord === word.length) {
                    setIsDeleting(true);
                }
            }
        };

        const timeout = setTimeout(type, 200);
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndex, currentWord, indexInWord, isDeleting]);

    useEffect(() => {
        // getMenu()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='h-full'>
            <Carousel slides={slides} />

            <section className='my-8'>
                <div className="relative isolate overflow-hidden bg-gray-900 py-12 sm:py-20">
                    <img
                        src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
                        alt=""
                        className="absolute inset-0 -z-10 h-full w-full object-cover"
                    />
                    <div
                        className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div
                        className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#ff4694] to-[#776fff] opacity-20"
                            style={{
                                clipPath:
                                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                            }}
                        />
                    </div>
                    <div className="mx-auto max-w-7xl px-4 lg:px-6 grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="mx-auto lg:mx-0">
                            <h2 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">ТАНИЛЦУУЛГА</h2>
                            <p className="mt-6 text-sm leading-6 text-gray-300">
                                Мал Аж Ахуйн Эрдэм Шинжилгээний хүрээлэнгийн эрдэмтдийн удирдлага, шууд оролцоотойгоор арвин
                                ашиг шимт малын үүлдэр, үүлдрийн болон үржлийн хэсэг 35, омог 9,
                                хэвшил 1, удам 30, тэжээлийн олон наст ургамлын нутгийн сорт 10,
                                нэг, олон наст ургамлын 20 гаруй сортыг нутагшуулан үйлдвэрлэлд
                                нэвтрүүлсэн. Мөн эрдэмтдийн судалгаа, шинжилгээний үр дүнд 180
                                гаруй ном товхимол, бүтээл, судалгааны 500 гаруй тайлан бичиж
                                хэвлүүлснээс гадна технологи, заавар, зөвлөмж, стандарт, техникийн
                                нөхцөл 400 гаруй, шинэ бүтээл, патент 100 гаруйг боловсруулж баталгаажуулжээ.

                            </p>
                        </div>
                        <div>

                            <iframe
                                title='Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн'
                                width="100%"
                                height="394"
                                src="https://www.youtube-nocookie.com/embed/b0aZyjLW3IA?autoplay=1&amp;feature=oembed&amp;controls=0&amp;hd=0&amp;showinfo=0&amp;rel=0&amp;modestbranding=1&amp;enablejsapi=0&amp;enablejsapi=1"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen=""
                                data-uk-video=""
                            ></iframe>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="bg-library bg-cover bg-center bg-no-repeat bg-fixed">
                    <div
                        className="bg-gradient-to-b from-transparent to-black px-6 lg:px-8 py-8 lg:py-10"
                    >
                        <div className="mx-auto max-w-2xl lg:max-w-none">
                            <div className="text-center">
                                <h2 className="text-xl font-medium tracking-tight text-white sm:text-2xl">
                                    Шинжлэх ухаан, үйлдвэрлэл, оюуны бизнесийн цогцолбор болж хөгжинө
                                </h2>
                            </div>
                            <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-5">
                                {stats.map((stat) => (
                                    <div key={stat.id} className="flex flex-col bg-gray-300/10 p-8">
                                        <dt className="text-sm font-semibold leading-6 text-yellow-500">{stat.name}</dt>
                                        <dd className="order-first text-3xl font-semibold tracking-tight text-white">{stat.value}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </section>

        </div >
    )
}

export default Home