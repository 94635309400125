import React from 'react'
import BlogHeader from '../../BlogHeader'

const GoviinBus = () => {
    return (
        <div>
            <BlogHeader title={'goviin-bus'} />
            <div className="mx-auto max-w-7xl py-8 md:gap-4 bg-white px-8">
                <div className="flex flex-wrap justify-center items-center my-4">
                    <img
                        width={100}
                        src="/background-image/riah/Picture50.jpg"
                        alt="ӨМНӨГОВЬ АЙМАГ ДАХЬ ГОВИЙН БҮСИЙН БЭЛЧЭЭРИЙН МАЛ АЖ АХУЙН ЭРДЭМ ШИНЖИЛГЭЭНИЙ ХҮРЭЭЛЭН"
                    />
                    <div className='text-center text-primary-700 uppercase font-semibold p-3 max-w-xl'>
                        ӨМНӨГОВЬ АЙМАГ ДАХЬ ГОВИЙН БҮСИЙН БЭЛЧЭЭРИЙН МАЛ АЖ АХУЙН ЭРДЭМ ШИНЖИЛГЭЭНИЙ ХҮРЭЭЛЭН
                    </div>
                </div>
                <hr className='my-6' />
                <div className='text-justify text-gray-700 text-sm font-medium'>
                    <p className='my-3'>
                        Монгол улсын бүсчилсэн хөгжлийн үзэл баримтлалын дагуу
                        говийн бүсэд хаа-н шинжлэх ухаан, үйлдвэрлэл,
                        технологийн сургалт, эрдэм шинжилгээ, үйлдвэрлэлийн
                        өргөн цар хүрээтэй үйл ажиллагаа явуулах үүрэг
                        бүхий судалгааны төв болох эрхэм зорилготой.
                    </p>
                    <p className='my-3'>
                        <strong>Түүхэн товчоо: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>БНМАУ-ын СнЗ-ийн 1958 оны 6-р сарын 11-ний өдрийн 178-р тогтоолоор Өмнөговь аймгийн Булган суманд Мал аж ахуйн машинт станц нэртэйгээр, 40 гаруй орон тоотой байгуулагдан үүнээс нэг жилийн дараа 1959 онд Говийн бүсийн ХАА-н эрдэм шинжилгээний туршлага станц нэртэй хөдөө орон нутагт байгуулагдсан эрдэм шинжилгээний анхны байгууллага юм.  </li>
                        <li>1987 онд Говийн бүсийн ХАА-н эрдэм шинжилгээний туршлага станцыг МАХН-ын Төв Хороо, СнЗ-ийн 09 тоот тогтоолоор Говийн бүсийн бэлчээрийн мал аж ахуйн эрдэм шинжилгээний хүрээлэн  болгон өргөжүүлсэн.</li>
                        <li>1997 онд Говийн бүсийн бэлчээрийн мал аж ахуйн эрдэм шинжилгээний хүрээлэнг татан буулгаж, МААЭШХ-ийн Говийн бүсийн салбар болгон өөрчлөн зохион байгуулсан. </li>
                        <li>2016 онд Говийн бүсийн бэлчээрийн мал аж ахуйн эрдэм шинжилгээний хүрээлэн болгон өөрчилсөн.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Үйл ажиллагааны үндсэн чиглэл: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Говийн бүсийн ХАА-н эрдэм шинжилгээний туршлага станц нь анх байгуулагдахдаа говийн нөхцөлд хөдөө аж ахуйн төрөл бүрийн салбарт эрдэм шинжилгээ, судалгааны ажил явуулах, шинжлэх ухаан техник технологийн сүүлийн үеийн ололт амжилт, шинэ санаачилга, тэргүүний туршлагыг судлан үр дүнг үйлдвэрлэлд нэвтрүүлэх замаар мал аж ахуй, газар тариаланг тогтвортой хөгжүүлэх арга боловсруулах онол, практикийн үндсийг тавихад чиглэсэн үйл ажиллагаа явуулж байсан.  </li>
                        <li>Говийн бүсийн бэлчээрийн мал аж ахуйн эрдэм шинжилгээний хүрээлэн нь  говийн бүсийн малын чанар, үүлдэр угсааг сайжруулах, нутгийн шилмэл малын удмын сан, генетик нөөц, бэлчээрийг хамгаалах, тэжээлийн шинэ нөөц бий болгох чиглэлээр суурь болон хавсарга судалгаа явуулах</li>
                        <li>Говийн бүсийн байгаль цаг уур, экологийн нөхцөлд дасан зохицсон, ашиг шим сайтай шинэ үүлдэр, омгийн мал, ургамлын шинэ сорт бий болгох, илрүүлэн баталгаажуулах</li>
                        <li>Говийн бүс нутагт өөрийн орны болон гадаадын өндөр технологийг нутагшуулах, инноваци бүтээгдэхүүн бий болгох чиглэлээр үйл ажиллагаа явуулж байна.</li>
                    </ul>
                    <p className='my-3'>
                        <strong>Судалгааны ажлын товч үр дүн: </strong>
                    </p>
                    <ul className='list-disc pl-8'>
                        <li>Ноолуурын чиглэлийн “Говь гурван сайхан”, “Уулын бор” үүлдрийн ямаа, ноосны чиглэлийн “Өнжүүл” үржлийн хэсгийн ямаа, “Сүмбэр” үүлдрийн хонийг шинээр бий болгож, ямаа тэмээний аж ахуй, биологийн онцлог, удамших чадварыг судалсан материалыг үндэслэн “Монгол тэмээ”, “Монгол ямаа”-г үүлдрээр, нутгийн малын генефондыг хамгаалан өсгөх, ашиг шимийг нэмэгдүүлэх талаар явуулсан судалгааны ажлын үр дүнд Тулман хөхт  ямаанд явуулах үржил селекцийн арга боловсруулан, 2015, 2016 онд “Ханын хэцийн хүрэн”, “Галбын говийн улаан”, “Төхөм тунгалагийн хүрэн” тэмээг үүлдрээр баталгаажуулсан.  </li>
                        <li>Тус хүрээлэнгээс МУ-ын төрийн шагналтан 1, гавьяат зоотехникч 1, академич 1, шинжлэх ухааны доктор 1, боловсролын доктор 14, шилдэг зохион бүтээгч 2, улсын аварга малчин 2, ХАА-н аж ахуйн тэргүүний ажилтан 50 гаруй төрөн гарсан.</li>
                    </ul>
                </div>
                <figure className='my-6 px-8'>
                    <img src="/background-image/riah/Picture51.jpg" alt="" srcset="" />
                    <figcaption
                        className="text-sm text-neutral-600 dark:text-neutral-400 text-center"
                    >
                        Говийн бүсийн бэлчээрийн мал аж ахуйн эрдэм шинжилгээний хүрээлэнгийн 60 жилийн ойн арга хэмжээ (2019 он)
                    </figcaption>
                </figure>
                <hr className='my-6' />
            </div>
        </div>
    )
}

export default GoviinBus