import React from 'react'
import BlogHeader from '../../BlogHeader'

const Mah = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture15.jpg",
            description: "Лабораторийн хамт олон"
        },
        {
            imgpath: "/background-image/riah/Picture16.jpg",
            description: "Зүсэгч микротомд дээж зүсэж байгаа нь"
        },
        {
            imgpath: "/background-image/riah/Picture17.jpg",
            description: "Арьсны дээжийг будаж спиртийн уусмалаар бэхжүүлж байгаа нь"
        },
        {
            imgpath: "/background-image/riah/Picture18.jpg",
            description: "Арьсны дээжийг хөлдөөгч микротомд зүсэв"
        },
    ];
    return (
        <div>
            <BlogHeader title={'mah'} />
            <div className="mx-auto max-w-7xl my-8 md:gap-4 bg-white p-4">
                <div>
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        МАЛЫН БҮТЭЭГДЭХҮҮНИЙ ГИСТОЛОГИ, МАХ СУДЛАЛЫН ЛАБОРАТОРИ
                    </div>
                    <hr className='my-6' />
                    <div className='text-justify text-gray-700 text-sm font-medium'>

                        <p><strong>Үндсэн үйл ажиллагаа: </strong></p>
                        <p>
                            Мал, амьтны гаралтай бүх төрлийн түүхий эд, дээжид бичил бүтцийн шинжилгээ хийх
                        </p>
                        <p className='mt-6'><strong>Судалгааны чиглэл: </strong></p>
                        <p>Жилд дунджаар 1.000-1.200 дээжийг шинжлэх хүчин чадалтай.</p>
                        <ul className='list-disc pl-8'>
                            <li>Мал, амьтны эд, эрхтний бичил бүтэц, түүхий эдийн гэмтэл, чанарын өөрчлөлтийг тогтоох;  </li>
                            <li>Мал, амьтны үс, ноосны гистологи бүтэц, ширхэгтийн голч тодорхойлох;</li>
                            <li>Шүүх, гаалийн газраас ирүүлсэн мал амьтны гаралтай үс, ноосны ширхэгтэд гистологийн магадлан шинжилгээ хийх;</li>
                        </ul>
                    </div>
                    <hr className='my-6' />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2 mx-auto'>
                    {photos.map((item) => {
                        return <figure className="mb-4 inline-block max-w-sm mx-auto">
                            <img
                                src={item.imgpath}
                                width={"354"}
                                className="mb-4 h-auto max-w-full rounded-lg align-middle leading-none shadow-lg"
                                alt={item.description} />
                            <figcaption className="text-sm text-neutral-600 dark:text-neutral-400">
                                {item.description}
                            </figcaption>
                        </figure>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Mah