import React from 'react'
import ComponentLaborator from './ComponentLaborator';

const TejeeliinShingets = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture38.jpg",
            description: "Ин витрод ашиглах гүзээний шингэн авах хонины мэс ажилбар"
        },
        {
            imgpath: "/background-image/riah/Picture39.jpg",
            description: "СОУЭ, ХОУЭ тодорхойлох (NDF, ADF)"
        },
        {
            imgpath: "/background-image/riah/Picture40.jpg",
            description: "Ин витрод хий ялгаралтыг тодорхойлох "
        },
        {
            imgpath: "/background-image/riah/Picture41.jpg",
            description: "Ин вирод мятан хий хэмжих"
        },
    ];

    const mainservice = 'Хөдөө аж ахуйн мал амьтдын тэжээл, тэжээлийн түүхий эд, бэлчээр болон таримал ургамлын шингэцийг in vitro болон  in vivo  орчинд тодорхойлох'
    const chiglel = [
        'Мал тэжээл, тэжээлийн түүхий эд, бэлчээр болон таримал ургамлын шингэц, шимт чанар тодорхойлох ',
        'Ерөнхий шимт чанарыг солилцлын энергиэр үнэлэх, МДж/кг',
        'Органик бодисын шингэц, %',
        'Азотгүй хандлаг бодис, %',
        'Хөдөө аж ахуйн мал амьтдын тэжээлийн норм, жор боловсруулах',
    ];
    return (
        <ComponentLaborator
            title={'tejeeliin-shingets'}
            photos={photos}
            maintitle={'МАЛЫН ТЭЖЭЭЛИЙН ШИНГЭЦ СУДЛАЛЫН ЛАБОРАТОРИ'}
            mainservice={mainservice}
            chiglel={chiglel}
        />
    )
}

export default TejeeliinShingets