import React from 'react'
import ComponentLaborator from './ComponentLaborator'

const Belcheer = () => {
    const photos = [
        {
            imgpath: "/background-image/riah/Picture46.jpg",
            description: "ШУ-ны доктор С.Цэрэндаш лабораторийн судлаачдад зөвлөгөө өгч буй мөч"
        },
        {
            imgpath: "/background-image/riah/Picture47.jpg",
            description: "Цөлөрхөг хээрийн бэлчээрийн талбайн судалгаа"
        },
        {
            imgpath: "/background-image/riah/Picture48.jpg",
            description: "Хээрийн судалгаагаар цуглуулсан мэдээллээ боловсруулж, зураглал хийх явц"
        },
        {
            imgpath: "/background-image/riah/Picture49.jpg",
            description: "Лабораторид хадгалагдаж буй ургамлын хатаадас"
        },
    ];
    const mainservice = 'Монгол орны байгалийн хадлан, бэлчээр дэх тэжээлийн ургамлын мэдээллийн нэгдсэн сан байгуулах, ургамлын талаарх цогц мэдээлэл бий болгох, ургамлын бэлчээрийн даац, нөөцийг тогтоох, бэлчээрийн ургамлын мониторингийн судалгаа явуулж, бэлчээрийн менежментийн төлөвлөгөө, заавар зөвлөмж боловсруулах';
    const chiglel = [
        'Ойт хээр, хээр, өндөр уулын бүсийг хамруулан цуглуулсан тэжээлийн ургамлын хатаамал болон зүйл ургамал тус бүртэй холбоотой мэдээллийн нэгдсэн сантай.',
        'Бэлчээрийн ургамлын бүлгэмдлийн хэв шинжийн ургац, байрлал, тархац, бэлчээрийн даац, нөөцийг тогтоох, тогтвортой ашиглах, сэлгээний хувилбаруудыг боловсруулах; ',
        'Бэлчээрийн ургамлын мониторингийн судалгаа явуулж, Монгол улсын хэмжээнд бэлчээрийн менежментийн төлөвлөгөө, заавар зөвлөмж боловсруулан хэрэгжүүлэх; ',
        'Бэлчээр, хадлангийн ургамлын бүтээмж, нөхөн сэргэх чадавхыг тодорхойлох;',
        'Бэлчээр, хадлангийн ургамалжлын төлөв байдал, ашиглалт, нөөц, даац, доройтлын үнэлгээ хийх;',
        'Бэлчээр, хадлангийн газрын ашиглалт тогтоох, даацын үнэлгээ гаргах, 1:100000 машстабаар зураглал хийж гүйцэтгэх;',
    ];
    return (
        <ComponentLaborator
            title={'belcheer'}
            photos={photos}
            maintitle={'БЭЛЧЭЭРИЙН МОНИТОРИНГ, ЗУРАГЛАЛЫН ЛАБОРАТОРИ'}
            mainservice={mainservice}
            chiglel={chiglel}
        ></ComponentLaborator >
    )
}

export default Belcheer