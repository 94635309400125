import React from 'react'
import BlogHeader from '../BlogHeader'

const ZahiralMendchilgee = () => {
    return (
        <div>
            <BlogHeader title={'director_greetings'} />
            <div className="mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-3 my-8 md:gap-4 bg-white">
                <div>
                    <img src="/image/Picture5.jpg" alt="" />
                </div>
                <div className="col-span-2 px-2">
                    <div className='text-center text-primary-700 uppercase font-semibold px-3'>
                        Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэнгийн
                        Захирал, Монгол Улсын зөвлөх мал зүйч, Доктор Т.Батсүх
                    </div>
                    <hr className='my-6'/>
                    <div className='text-justify text-gray-700 text-sm font-medium'>
                        <p className='my-3'>
                            Монгол Улсад орчин цагийн шинжлэх ухаан үүсэж хөгжсөний 100 жил, Ж.Самбуугийн нэрэмжит
                            Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн үүсэн байгуулагдсаны түүхт 60 жилийн ойг тохиолдуулан
                            үе үеийн эрдэмтэн судлаачид, удирдлагууд,
                            ажилтан, ажилчид болон эрдмийн хамт олондоо чин сэтгэлийн баярын мэндчилгээг дэвшүүлье.
                        </p>
                        <p className='mb-3'>
                            Монгол орны цаг уурын нөхцөлд гойд дасан зохицсон өндөр ашиг шимт
                            малын үүлдэр, омог, хэвшил 47, тэжээлийн ургамлын нутгийн таримал
                            сорт 10-ыг шинээр бий болгосон нь улс орны эдийн засгийн хөгжилд
                            чухал нөлөө үзүүлсээр байна.
                        </p>
                        <p className='mb-3'>
                            Судалгаа шинжилгээний он жилүүдэд академич 10, төрийн соёрхолт 8,
                            гавьяатууд 23, шинжлэх ухааны доктор 22, боловсролын докторын зэрэгтэй
                            130 гаруй эрдэмтэн төрөн гарч, мал аж ахуй, бэлчээр тэжээлийн
                            чиглэлийн 400 технологи, шинэ биет бүтээгдэхүүний загвар,
                            зөвлөмж 147, стандарт 60 гаруй, шинэ бүтээл, патент 99-ийг боловсруулж
                            баталгаажуулсныг онцлон дурдаж байна.

                        </p>
                        <p className='mb-3'>
                            Мал Аж Ахуйн Эрдэм Шинжилгээний Хүрээлэн үүсэн байгуулагдсаны
                            түүхт ойгоо өргөн тэмдэглэж байгаа энэ баярт мөчид үе үеийн
                            эрдэмтэн судлаачид  болон тэдний гэр бүлд эрүүл энх, сайн сайхан,
                            амжилт бүтээлийн дээдийг хүсье.
                        </p>
                    </div>
                    <hr className='my-6'/>
                </div>
            </div>
        </div>
    )
}

export default ZahiralMendchilgee